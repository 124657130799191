.image-gallery-slide img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.primary-text {
    color: #679aff;
}

.bg-white {
    background-color: white;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
}
