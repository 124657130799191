.rce-container-citem.active > .rce-citem {
    background-color: rgb(119, 217, 247);
}

.rce-mbox.rce-mbox-right {
    background-color: #0084ff;
    color: white;
}

.rce-mbox.rce-mbox-right > .rce-mbox-body > .rce-mbox-time {
    color: white;
}

.rce-mbox.rce-mbox-right > .rce-mbox-right-notch > path {
    fill: #0084ff;
}
