.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #ffffff;
    color: #679aff;
}

.App-bo-header {
    background-color: #0981de;
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.bg-container {
    background-color: #f4f4f4;
    flex: 1;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #05050575;
    z-index: 100;
}

.page-content {
    max-width: 1380px;
}

.ant-layout-sider.ant-layout-sider-dark {
    background-color: #0d1150;
}

.ant-layout-sider.ant-layout-sider-dark > div > ul {
    background-color: #0d1150;
}

.item-pick.selected {
    border: 3px solid #0981de;
}

.none-select-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: none;
    pointer-events: none;
    background-color: #0c0c0c4f;
}
